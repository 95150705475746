export enum AB_TESTS_KEYS {
  PWA_COMMUNICATION_V4 = 'pwa-communication-v4',
  SUB_LIMITED_WORDS_MODAL = 'sub_limited_words_modal',
}

export const AB_TEST_FEATURES: Record<
  AB_TESTS_KEYS,
  {
    key: AB_TESTS_KEYS;
    variants: {
      control: string;
      variant_1: string;
      [key: string]: string;
    };
    defaultVariant: string;
  }
> = {
  [AB_TESTS_KEYS.PWA_COMMUNICATION_V4]: {
    key: AB_TESTS_KEYS.PWA_COMMUNICATION_V4,
    variants: {
      control: 'v1',
      variant_1: 'v4',
    },
    defaultVariant: 'v1',
  },
  [AB_TESTS_KEYS.SUB_LIMITED_WORDS_MODAL]: {
    key: AB_TESTS_KEYS.SUB_LIMITED_WORDS_MODAL,
    variants: {
      control: 'control',
      variant_1: 'tested',
    },
    defaultVariant: 'control',
  },
};

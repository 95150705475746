import React from 'react';

import ChromeDesktop from '~/features/pwa/versions/V4/AddToHomeModal/components/browser/ChromeDesktop';
import ChromeMobileAndroid from '~/features/pwa/versions/V4/AddToHomeModal/components/browser/ChromeMobileAndroid';
import ChromeMobileIOS from '~/features/pwa/versions/V4/AddToHomeModal/components/browser/ChromeMobileIOS';
import EdgeDesktop from '~/features/pwa/versions/V4/AddToHomeModal/components/browser/EdgeDesktop';
import SafariDesktop from '~/features/pwa/versions/V4/AddToHomeModal/components/browser/SafariDesktop';
import SafariMobile from '~/features/pwa/versions/V4/AddToHomeModal/components/browser/SafariMobile';
import Android from '~/features/pwa/versions/V4/AddToHomeModal/components/os/Android';
import IOS from '~/features/pwa/versions/V4/AddToHomeModal/components/os/IOS';
import Mac from '~/features/pwa/versions/V4/AddToHomeModal/components/os/Mac';
import Windows from '~/features/pwa/versions/V4/AddToHomeModal/components/os/Windows';
import { Device } from '~/utils/userAgentInfo';

export enum SCREENS {
  OS = 'os',
  BROWSER = 'browser',
}

export const CONFIG: Record<
  string,
  {
    [SCREENS.OS]: React.FC<{
      onDismiss: () => void;
      onSubmit: () => void;
    }>;
    [SCREENS.BROWSER]: React.FC<{
      onSubmit: () => void;
    }>;
  }
> = {
  [Device.Ios_Safari]: {
    [SCREENS.OS]: IOS,
    [SCREENS.BROWSER]: SafariMobile,
  },
  [Device.Ios_Chrome]: {
    [SCREENS.OS]: IOS,
    [SCREENS.BROWSER]: ChromeMobileIOS,
  },
  [Device.Android_Chrome]: {
    [SCREENS.OS]: Android,
    [SCREENS.BROWSER]: ChromeMobileAndroid,
  },
  [Device.Mac_Chrome]: {
    [SCREENS.OS]: Mac,
    [SCREENS.BROWSER]: ChromeDesktop,
  },
  [Device.Mac_Edge]: {
    [SCREENS.OS]: Mac,
    [SCREENS.BROWSER]: EdgeDesktop,
  },
  [Device.Mac_Safari]: {
    [SCREENS.OS]: Mac,
    [SCREENS.BROWSER]: SafariDesktop,
  },
  [Device.Windows_Chrome]: {
    [SCREENS.OS]: Windows,
    [SCREENS.BROWSER]: ChromeDesktop,
  },
  [Device.Windows_Edge]: {
    [SCREENS.OS]: Windows,
    [SCREENS.BROWSER]: EdgeDesktop,
  },
};

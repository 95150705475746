import React from 'react';

import languages from 'language-list';

import { CardConstructorType } from '~/modules/onboarding-new/Card';
import Template1 from '~/modules/onboarding-new/cardTemplates/Template1';
import Template2 from '~/modules/onboarding-new/cardTemplates/Template2';
import { TemplateVariantProps } from '~/modules/onboarding-new/types';
import analytics from '~/services/analytics';

export const OnboardingPath = '/onboarding';

export const OnboardingTitle = 'Onboarding';

const prioritizedLanguages = [
  'English',
  'Arabic',
  'Chinese',
  'Spanish',
  'French',
  'Hindi',
  'Romanian',
  'Vietnamese',
  'Nepali',
];

const LANGUAGE_LIST = [
  'Ukrainian',
  ...(languages()
    .getData()
    .map(({ language }) => language) as string[]),
].sort((a, b) => {
  const indexA = prioritizedLanguages.indexOf(a);
  const indexB = prioritizedLanguages.indexOf(b);

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  if (indexA !== -1) {
    return -1;
  }

  if (indexB !== -1) {
    return 1;
  }

  return a.localeCompare(b);
});

export enum ONBOARDING_SAVE_KEYS {
  COMPANY_SIZE = 'company_size',
  PROFESSION = 'profession',
  LANGUAGE = 'language',
  POSITION = 'position',
  PURPOSES = 'purposes',
  AI_EXPERIENCE = 'ai_experience',
}

export enum CARDS {
  PURPOSES = 'purposes',
  WORK_PROFESSION = 'work_profession',
  WORK_POSITION = 'work_position',
  WORK_COMPANY_SIZE = 'work_company_size',
  WORK_LANGUAGE = 'work_language',
  WORK_AI_EXPERIENCE = 'work_ai_experience',
  STUDY_PROFESSION = 'study_profession',
  STUDY_LANGUAGE = 'study_language',
  STUDY_AI_EXPERIENCE = 'study_ai_experience',
  PERSONAL_PROFESSION = 'personal_profession',
  PERSONAL_LANGUAGE = 'personal_language',
  PERSONAL_AI_EXPERIENCE = 'personal_ai_experience',
}

export enum PROFESSIONS {
  STUDENT = 'Student',
  CONTENT_WRITER = 'Content Writer',
  AUTHOR = 'Author',
  ACCOUNTANT = 'Accountant',
  TEACHER_LECTURER = 'Teacher/Lecturer',
  RESEARCHER_ACADEMIA = 'Researcher/Academia',
  MARKETER = 'Marketer',
  SALES_BUSINESS_MANAGER = 'Sales & Business Manager',
  CUSTOMER_SERVICE_SPECIALIST = 'Customer Service Specialist',
  COPYWRITER = 'Copywriter',
  MANAGER_EXECUTIVE = 'Manager/Executive',
  CUSTOMER_SUCCESS_MANAGER = 'Customer Success Manager',
  DATA_ANALYST = 'Data Analyst',
  DESIGNER = 'Designer',
  IT_SPECIALIST = 'IT Specialist',
  JOURNALIST = 'Journalist',
  LAWYER = 'Lawyer',
  OPERATION_SPECIALIST = 'Operation Specialist',
  PRODUCT_DESIGNER = 'Product Designer',
  PRODUCT_MANAGER = 'Product Manager',
  PROJECT_MANAGER = 'Project Manager',
  RECRUITER_HR = 'Recruiter/HR',
  SOFTWARE_DEVELOPER = 'Software Developer',
  UX_WRITER = 'UX Writer',
}

const PROFESSION_LIST = Object.values(PROFESSIONS);

export const CARD_CONFIG: Record<
  CARDS,
  CardConstructorType<TemplateVariantProps>
> = {
  [CARDS.PURPOSES]: {
    key: CARDS.PURPOSES,
    saveKey: ONBOARDING_SAVE_KEYS.PURPOSES,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose purpose', { value }),
    Template: Template1,
    templateProps: {
      variants: [
        { value: 'Work', nextCard: CARDS.WORK_PROFESSION },
        { value: 'Study', nextCard: CARDS.STUDY_PROFESSION },
        { value: 'Personal', nextCard: CARDS.PERSONAL_PROFESSION },
      ],
      Text: ({ children }) => <>I'm here mostly for {children} purposes</>,
      isWithDot: true,
    },
  },
  [CARDS.WORK_PROFESSION]: {
    key: CARDS.WORK_PROFESSION,
    saveKey: ONBOARDING_SAVE_KEYS.PROFESSION,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose profession', { value }),
    Template: Template2,
    templateProps: {
      variants: PROFESSION_LIST.map((item) => ({
        value: item,
      })),
      nextCard: CARDS.WORK_POSITION,
      Text: ({ children }) => <>In my profession I'm a {children}</>,
      isWithDot: true,
    },
  },
  [CARDS.WORK_POSITION]: {
    key: CARDS.WORK_POSITION,
    saveKey: ONBOARDING_SAVE_KEYS.POSITION,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose position', { value }),
    Template: Template1,
    templateProps: {
      variants: [
        { value: 'Team Member' },
        { value: 'Team Lead' },
        { value: 'Executive' },
      ],
      nextCard: CARDS.WORK_COMPANY_SIZE,
      Text: ({ children }) => <>More specifically, I'm a {children}</>,
    },
  },
  [CARDS.WORK_COMPANY_SIZE]: {
    key: CARDS.WORK_COMPANY_SIZE,
    saveKey: ONBOARDING_SAVE_KEYS.COMPANY_SIZE,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose organization', { value }),
    Template: Template1,
    templateProps: {
      variants: [{ value: '1-50' }, { value: '51-100' }, { value: '100+' }],
      nextCard: CARDS.WORK_LANGUAGE,
      Text: ({ children }) => <>in a company of {children} employees</>,
      isWithDot: true,
    },
  },
  [CARDS.WORK_LANGUAGE]: {
    key: CARDS.WORK_LANGUAGE,
    saveKey: ONBOARDING_SAVE_KEYS.LANGUAGE,
    Template: Template2,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose language', { value }),
    templateProps: {
      variants: LANGUAGE_LIST.map((item) => ({
        value: item,
      })),
      Text: ({ children }) => <>My native language is {children}</>,
      isWithDot: true,
      nextCard: CARDS.WORK_AI_EXPERIENCE,
    },
  },
  [CARDS.WORK_AI_EXPERIENCE]: {
    key: CARDS.WORK_AI_EXPERIENCE,
    saveKey: ONBOARDING_SAVE_KEYS.AI_EXPERIENCE,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose ai experience', { value }),
    Template: Template1,
    templateProps: {
      variants: [
        { value: 'Profound' },
        { value: 'Used Couple Times' },
        { value: 'Not Used' },
      ],
      Text: ({ children }) => (
        <>
          My experience with other AI tools (e.g ChatGPT, Bard): <br />{' '}
          {children}
        </>
      ),
    },
    isLastCard: true,
  },
  [CARDS.STUDY_PROFESSION]: {
    key: CARDS.STUDY_PROFESSION,
    saveKey: ONBOARDING_SAVE_KEYS.PROFESSION,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose profession', { value }),
    Template: Template2,
    templateProps: {
      variants: PROFESSION_LIST.map((item) => ({
        value: item,
      })),
      nextCard: CARDS.STUDY_LANGUAGE,
      Text: ({ children }) => <>In my profession I'm a {children}</>,
      isWithDot: true,
    },
  },
  [CARDS.STUDY_LANGUAGE]: {
    key: CARDS.STUDY_LANGUAGE,
    saveKey: ONBOARDING_SAVE_KEYS.LANGUAGE,
    Template: Template2,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose language', { value }),
    templateProps: {
      variants: LANGUAGE_LIST.map((item) => ({
        value: item,
      })),
      Text: ({ children }) => <>My native language is {children}</>,
      isWithDot: true,
      nextCard: CARDS.STUDY_AI_EXPERIENCE,
    },
  },
  [CARDS.STUDY_AI_EXPERIENCE]: {
    key: CARDS.STUDY_AI_EXPERIENCE,
    saveKey: ONBOARDING_SAVE_KEYS.AI_EXPERIENCE,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose ai experience', { value }),
    Template: Template1,
    templateProps: {
      variants: [
        { value: 'Profound' },
        { value: 'Used Couple Times' },
        { value: 'Not Used' },
      ],
      Text: ({ children }) => (
        <>
          My experience with other AI tools (e.g ChatGPT, Bard): <br />{' '}
          {children}
        </>
      ),
    },
    isLastCard: true,
  },
  [CARDS.PERSONAL_PROFESSION]: {
    key: CARDS.PERSONAL_PROFESSION,
    saveKey: ONBOARDING_SAVE_KEYS.PROFESSION,
    Template: Template2,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose profession', { value }),
    templateProps: {
      variants: PROFESSION_LIST.map((item) => ({
        value: item,
      })),
      nextCard: CARDS.PERSONAL_LANGUAGE,
      Text: ({ children }) => <>In my profession I'm a {children}</>,
      isWithDot: true,
    },
  },
  [CARDS.PERSONAL_LANGUAGE]: {
    key: CARDS.PERSONAL_PROFESSION,
    saveKey: ONBOARDING_SAVE_KEYS.LANGUAGE,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose language', { value }),
    Template: Template2,
    templateProps: {
      variants: LANGUAGE_LIST.map((item) => ({
        value: item,
      })),
      Text: ({ children }) => <>My native language is {children}</>,
      isWithDot: true,
      nextCard: CARDS.PERSONAL_AI_EXPERIENCE,
    },
  },
  [CARDS.PERSONAL_AI_EXPERIENCE]: {
    key: CARDS.PERSONAL_AI_EXPERIENCE,
    saveKey: ONBOARDING_SAVE_KEYS.AI_EXPERIENCE,
    onSubmitCard: (value) =>
      analytics.trackEvent('onboarding.2 - choose ai experience', { value }),
    Template: Template1,
    templateProps: {
      variants: [
        { value: 'Profound' },
        { value: 'Used Couple Times' },
        { value: 'Not Used' },
      ],
      Text: ({ children }) => (
        <>
          My experience with other AI tools (e.g ChatGPT, Bard): <br />{' '}
          {children}
        </>
      ),
    },
    isLastCard: true,
  },
};

import React, { FC } from 'react';

import { useModalContext } from '~/contexts/modal/ModalContext';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import { Device } from '~/utils/userAgentInfo';

import { CONFIG } from './config';

type Props = {
  device: Device;
  onSubmit: () => void;
  onDismiss: () => void;
};

const AddToHomeModalV4: FC<Props> = ({ device, onSubmit, onDismiss }) => {
  const BrowserComponent = CONFIG[device].browser;
  const OSComponent = CONFIG[device].os;
  const { handleOpenModal } = useModalContext();
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  const handleContinue = (): void => {
    handleOpenModal({
      maxWidth: isDesktop ? 'max-content' : '460px',
      hideCloseButton: true,
      disableBackdropClick: true,
      paddingSize: '0px',
      position:
        device === Device.Mac_Safari
          ? { horizontal: 'right', vertical: 'top' }
          : { horizontal: 'center', vertical: 'center' },
      component: () => <BrowserComponent onSubmit={onSubmit} />,
    });
  };

  return (
    <div style={{ maxWidth: '720px' }}>
      <OSComponent onSubmit={handleContinue} onDismiss={onDismiss} />
    </div>
  );
};

export default AddToHomeModalV4;
